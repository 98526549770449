import { Avatar, Box, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { useMediaQuery } from "@mui/material";

function Testimonials() {
    const isTab = useMediaQuery('(max-width: 768px)');
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: '' }}
                onClick={onClick}
            >
                <IoIosArrowForward style={{ width: '30px', height: '30px', color: "gray" }} />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            >
                <IoIosArrowBack style={{ width: '30px', height: '30px', color: "gray" }} />
            </div>
        );
    }

    const sliderSettings = {
        dots: true,
        infinite: true,
        arrows: isTab ? false : true,
        speed: 150,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        customPaging: (i) => (
            <Box
                sx={{
                    width: 10,
                    height: 10,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: '50%',
                    transition: 'background-color 0.3s ease, transform 0.3s ease',
                    '&:hover': {
                        backgroundColor: '#fff',
                        transform: 'scale(1.1)',
                    },
                }}
            />
        ),
        appendDots: (dots) => (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <Box sx={{ width: isTab ? "30%" : "10%", display: 'flex', justifyContent: 'space-between' }}>
                    {dots}
                </Box>
            </Box>
        ),
    };

    const data = [
        {
            text: 'Finally my first dream home from a trusted builder, the overall customer service is amazing.',
            name: "SRINATH SUBRAMANIAM"
        },
        {
            text: 'I have invested in one of the Concorde projects as they provide the best facilities, amenities and always look for their customer’s comfort.',
            name: "Rishi Kapoor"
        },
        {
            text: 'Concorde is one of the most reputed Realty Companies in Bangalore who’s been providing quality villas and apartments. Bought one of their in electronic city and had no issues with bank loans and transactions.',
            name: "Murali Gopinath"
        },
        {
            text: 'When i was thinking to buy a flat in Bangalore i contacted many friends, most of them suggested Concorde projects. I am very happy that now i bought a flat in Concorde Windrush project in electronic city. Most of this group projects have good amenities.',
            name: "Vinayakumar K"
        },
    ]

    function showSlider() {
        return data.map((item, index) => {
            return (
                <div>
                    <div key={index} style={{ width: "100%" }}>
                        <Box
                            sx={{
                                width: '100%',
                                height: 300,
                                background: "#0D5BA9",
                                borderRadius: "20px",
                                color: "#fff",
                                fontWeight: 'bold',
                                padding: "0px 20px",
                                boxSizing: 'border-box',
                                textAlign: 'center',
                            }}
                        >
                            <Typography variant="body2" sx={{ display: 'flex', fontSize: '1.2rem', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <span style={{ fontSize: '1.5rem' }}>99</span>
                                <div style={{ marginTop: '10px' }}>{item?.text}</div>
                                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                                    <Avatar alt="Remy Sharp" src={item?.image} sx={{ width: 50, height: 50 }} />
                                    <div>{item?.name}</div>
                                </div>
                            </Typography>
                        </Box>
                    </div>
                </div >
            );
        });
    }

    return (
        <Box
            sx={{
                marginTop: '5%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >

            <center>
                <h3 style={{
                    fontSize: 15,
                    textTransform: 'uppercase',
                    color: '#0D5BA9',
                    margin: '0 auto 2%'
                }}>Testimonials</h3>
            </center>

            <Box style={{ width: isTab ? "90%" : "45%", marginTop: "10px" }}>
                <Slider {...sliderSettings}>
                    {showSlider()}
                </Slider>

            </Box>

        </Box>
    )
}

export default Testimonials;