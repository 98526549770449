import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

// Keyframes for fade-in and fade-out animation
const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

// Styled component for the container with static background
const Container = styled.div`
  background-color: #005BA9;
  padding: 25px 20px;
  text-align: center;
`;

// Styled component for the text animation
const AnimatedText = styled.div`
  font-size: 1.6rem;
  color: #ffd700; /* Yellow color */
  animation: ${(props) => (props.fadeType === "in" ? fadeIn : fadeOut)} 2s ease;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const messages = [
    "Delivered 25+ million sq. ft. of real estate excellence.",
    "Created lifestyle assets with immense value appreciation.",
    "Developed 29 incredible properties across Bengaluru.",
    "Catered to 15+ prime locations.",
    "Created prominent neighbourhoods in E-city, Kanakapura road, Chikkaballapura and Malur.",
    "Served 14,000+ happy families.",
];

const TextAnimation = () => {
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const [fadeType, setFadeType] = useState("in");

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (fadeType === "in") {
                setFadeType("out");
            } else {
                setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
                setFadeType("in");
            }
        }, 3000); // 3 seconds for each phase (fade-in or fade-out)

        return () => clearTimeout(timeout);
    }, [fadeType]);

    return (
        <Container>
            <AnimatedText fadeType={fadeType}>{messages[currentMessageIndex]}</AnimatedText>
        </Container>
    );
};

export default TextAnimation;
