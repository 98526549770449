import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Header = () => {

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        setIsDrawerOpen(open);
    };

    const menuItems = [
        { number: "01", text: "About us", link: "https://www.concorde.in/about-us.php" },
        { number: "02", text: "Residential Properties", link: "https://www.concorde.in/residential-properties.php" },
        { number: "02", text: "Commercial Properties", link: "https://www.concorde.in/commercial.php" },
        { number: "04", text: "Channel Partners", link: "https://www.concorde.in/existing-channel-partner.php" },
        { number: "05", text: "Careers", link: "https://www.concorde.in/career.php" },
        { number: "06", text: "Book Now", link: "https://www.concorde.in/booking-form/" },
        { number: "07", text: "Contact us", link: "https://www.concorde.in/contact-us.php" },
    ];
    return (
        <AppBar position="static" sx={{ backgroundColor: '#fff', color: '#000', height: '60px', position: 'sticky', top: 0, zIndex: 999 }}>
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6" component="div" sx={{ pl: 4 }} >
                    <img src="/images/concorde-logo.png" alt="Concorde Logo" style={{ width: '200px', verticalAlign: 'middle' }} />
                </Typography>

                <Box sx={{ pr: 4 }}>
                    {/* <IconButton onClick={toggleDrawer(true)}>
                        <MenuIcon sx={{ fontSize: '30px', color: "#fff", backgroundColor: "#0D5BA9", padding: "1px 3px", fontWeight: "bold", borderRadius: "5px" }} />
                    </IconButton> */}
                </Box>

                <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                    <Box
                        sx={{ width: "100vw", boxSizing: "border-box" }}
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                    >
                        <AppBar position="static" sx={{ backgroundColor: '#fff', height: '60px' }}>
                            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="h6" component="div" sx={{ pl: 4 }} >
                                    <img src="/images/concorde-logo.png" alt="Concorde Logo" style={{ width: '200px', verticalAlign: 'middle' }} />
                                </Typography>

                                <Box sx={{ pr: 4 }}>
                                    {/* <IconButton onClick={toggleDrawer(true)}>
                                        <MenuIcon sx={{ fontSize: '30px', color: "#fff", backgroundColor: "#0D5BA9", padding: "1px 3px", fontWeight: "bold", borderRadius: "5px" }} />
                                    </IconButton> */}
                                </Box>

                            </Toolbar>
                        </AppBar>

                        <Box sx={{ padding: "40px 100px" }}>
                            {menuItems.map((item, index) => (
                                <List key={index} disablePadding>
                                    <ListItem disableGutters sx={{ display: 'flex', alignItems: 'center', paddingY: 1 }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#9e9e9e', fontSize: '1rem', marginRight: '8px' }}>
                                            {item.number}
                                        </Typography>
                                        <a target='_blank' href={item.link} style={{ textDecoration: 'none' }}>
                                            <ListItemText
                                                primary={item.text}
                                                primaryTypographyProps={{
                                                    sx: {
                                                        fontSize: '1.7rem',
                                                        color: '#000',
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                            />
                                        </a>
                                    </ListItem>
                                </List>
                            ))}
                        </Box>
                    </Box>
                </Drawer>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
