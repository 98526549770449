import React, { useState } from 'react';

const FloorPlan = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    const floorPlans = [
        {
            title: "2 BHK + 2T",
            imageSrc: "https://www.concorde.in/assets-new/images/neo/fp/2.jpg", // Replace with your image source
            facing: "West",
            carpetArea: "818 sq. ft.",
            superBuiltUpArea: "1241 sq. ft."
        },
        {
            title: "3 BHK + 2T",
            imageSrc: "https://www.concorde.in/assets-new/images/neo/fp/10.jpg", // Replace with your image source
            facing: "East",
            carpetArea: "1000 sq. ft.",
            superBuiltUpArea: "1500 sq. ft."
        },
        {
            title: "3 BHK + 3T",
            imageSrc: "https://www.concorde.in/assets-new/images/neo/fp/9.jpg", // Replace with your image source
            facing: "North",
            carpetArea: "1200 sq. ft.",
            superBuiltUpArea: "1600 sq. ft."
        }
    ];

    return (
        <div className="floor-plan-viewer">
            {/* Tabs Navigation */}
            <div className="tabs-navigation">
                {floorPlans.map((plan, index) => (
                    <button
                        key={index}
                        onClick={() => setSelectedTab(index)}
                        className={`tab-button ${selectedTab === index ? "active" : ""}`}
                    >
                        {plan.title}
                    </button>
                ))}
            </div>

            {/* Tab Content */}
            <div className="tab-content">
                {floorPlans.map((plan, index) => (
                    <div
                        key={index}
                        className={`floor-plan ${selectedTab === index ? "visible" : "hidden"}`}
                    >
                        <img src={plan.imageSrc} alt={`Floor Plan of ${plan.title}`} className="floor-plan-image" />
                        <div className="floor-plan-details">
                            {/* <div className="detail-item">
                                <span className="label">Facing:</span>
                                <span className="value">{plan.facing}</span>
                            </div>
                            <div className="detail-item">
                                <span className="label">Carpet Area:</span>
                                <span className="value">{plan.carpetArea}</span>
                            </div>
                            <div className="detail-item">
                                <span className="label">Super Built-up Area (SBA):</span>
                                <span className="value">{plan.superBuiltUpArea}</span>
                            </div> */}
                            {/* <button className="view-masterplan-button">
                                View Masterplan
                            </button> */}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FloorPlan;