import React, { useState } from 'react';
import FloorPlan from './FloorPlan';

const MainTabComp = () => {

    const [activeTab, setActiveTab] = useState('overview');


    const highlights = () => {
        return (
            <div className="opportunities-container">
                <h2 className="opportunities-title">Experience the Northeast Opportunities</h2>
                <div className="opportunities-grid">
                    <div className="opportunity-card">
                        <div className="opportunity-icon">📏</div>
                        <p>78% Open Spaces</p>
                    </div>
                    <div className="opportunity-card">
                        <div className="opportunity-icon">🏊‍♂️</div>
                        <p>40+ Amenities</p>
                    </div>
                    <div className="opportunity-card">
                        <div className="opportunity-icon">🏢</div>
                        <p>Concorde Signature Clubhouse 7,000 sq. ft.</p>
                    </div>
                    <div className="opportunity-card">
                        <div className="opportunity-icon">🏠</div>
                        <p>70% Efficient Home</p>
                    </div>
                    <div className="opportunity-card">
                        <div className="opportunity-icon">🔒</div>
                        <p>Tech Enabled homes</p>
                    </div>
                    <div className="opportunity-card">
                        <div className="opportunity-icon">⭐</div>
                        <p>SMART Philosophy</p>
                    </div>
                </div>
            </div>
        );
    };


    const clubhouse = () => {
        return (
            <div className="high-life-experience">
                <div className="header-section">
                    <div className="header-content">
                        <h2 style={{ fontWeight: 600, marginBottom: '3%' }}>The High-Life Experience</h2>
                        <ul>
                            <li>Chart your course through exceptional sports & lifestyle amenities.</li>
                            <li>Unlock membership privileges that point directly to your lifestyle goals.</li>
                            <li>Enjoy activities crafted for every age and interest.</li>
                            <li>Explore your hobbies in exclusive spaces.</li>
                        </ul>
                    </div>
                    <div className="header-image">
                        <img src="https://www.concorde.in/assets-new/images/neo/gal/3.jpg" alt="Building View" style={{ width: '70%' }} />
                    </div>
                </div>

                <div className="amenities-section">
                    <h3 style={{ fontWeight: 600 }}>Evolve Amenities</h3>
                    <div className="amenities-grid">
                        <div className="amenity-item">MULTIPURPOSE HALL</div>
                        <div className="amenity-item">BUSINESS LOUNGE</div>
                        <div className="amenity-item">GYMNASIUM</div>
                        <div className="amenity-item">INDOOR GAMES</div>
                        <div className="amenity-item">ZUMBA & DANCE ROOM</div>
                        <div className="amenity-item">VIRTUAL GOLF</div>
                        <div className="amenity-item">BOARD ROOM</div>
                        <div className="amenity-item">YOGA ROOM</div>
                    </div>
                </div>
            </div>
        );
    };

    const specifications = () => {
        const specs = [
            {
                title: 'Structure',
                img: 'https://www.concorde.in/assets-new/images/specs/structure.svg',
                content: 'RCC framed structure for A & B Block, shear wall system for C, D, E & F block, designed as per Seismic Zone 2.'
            },
            {
                title: 'Wall Finishing',
                img: 'https://www.concorde.in/assets-new/images/specs/wall-finish.svg',
                content: (
                    <ul>
                        <li><strong>Interior:</strong> Asian Tractor Emulsion paint or Equivalent</li>
                        <li><strong>Exterior:</strong> Two coats of exterior Asian Apex emulsion paint or Equivalent</li>
                    </ul>
                )
            },
            {
                title: 'Doors',
                img: 'https://www.concorde.in/assets-new/images/specs/doors.svg',
                content: (
                    <ul>
                        <li>Main door: Teak wood frame with Modular shutter & veneer finished with good quality hinge and digital lock.</li>
                        <li>Internal doors: Modular shutter, with good quality hinge and locks</li>
                        <li>Bathroom doors: Modular shutter with protective layer on one side</li>
                    </ul>
                )
            },
            {
                title: 'Plumbing',
                img: 'https://www.concorde.in/assets-new/images/specs/plumbing.svg',
                content: (
                    <ul>
                        <li>Standard quality plumbing fixtures - Jaguar or Equivalent</li>
                        <li>Single Lever diverter for all toilets</li>
                        <li>Hot and cold water supply for all toilet wash basins</li>
                    </ul>
                )
            },
            {
                title: 'Windows',
                img: 'https://www.concorde.in/assets-new/images/specs/windows.svg',
                content: (
                    <ul>
                        <li>Sliding UPVC windows with Mosquito mesh as per Design</li>
                        <li>Sliding door at balconies: UPVC Sliding</li>
                    </ul>
                )
            },
            {
                title: 'Sanitary Fittings',
                img: 'https://www.concorde.in/assets-new/images/specs/sanitary-fit.svg',
                content: (
                    <ul>
                        <li>Good quality sanitary fixtures in all toilets</li>
                        <li>Wall hung EWC</li>
                    </ul>
                )
            },
            {
                title: 'GRILLS AND RAILINGS',
                img: 'https://www.concorde.in/assets-new/images/specs/railings.svg',
                content: (
                    <ul>
                        <li>Invisible Grills in windows</li>
                        <li>MS railings for staircase</li>
                        <li>Glass railing / MS railing for balcony as per design</li>
                    </ul>
                )
            },
            {
                title: 'Electrical switches',
                img: 'https://www.concorde.in/assets-new/images/specs/electrical.svg',
                content: (
                    <ul>
                        <li>Standard quality electrical fixtures ISI make - Anchor Roma or Equivalent</li>
                    </ul>
                )
            },
            {
                title: 'Flooring',
                img: 'https://www.concorde.in/assets-new/images/specs/flooring.svg',
                content: (
                    <ul>
                        <li>Vitrified tiles in living/ dining, bedrooms and kitchen</li>
                        <li>Ceramic tiles in balcony, utility, and toilet floors.</li>
                        <li>Toilets Walls:Glazed tile for dado up to False ceiling.</li>
                    </ul>
                )
            },
            {
                title: 'Wiring',
                img: 'https://www.concorde.in/assets-new/images/specs/elect-cables.svg',
                content: (
                    <ul>
                        <li>VCopper FR wires, TV point in Living and Master bedroom, provision for AC points in all Bedrooms & living
                        </li>
                    </ul>
                )
            },
            {
                title: 'Granite',
                img: 'https://www.concorde.in/assets-new/images/specs/flooring.svg',
                content: (
                    <ul>
                        <li>Granite for Staircase up to 3 floors & smooth concrete finish / step tiles for upper floors.
                        </li>
                    </ul>
                )
            },
            {
                title: 'Tile',
                img: 'https://www.concorde.in/assets-new/images/specs/flooring.svg',
                content: (
                    <ul>
                        <li>Vitrified tile for Corridor Floor, texture paint up to 5 ft height for corridor wall
                        </li>
                    </ul>
                )
            },
        ];

        return (
            <div className="spec-container" style={{ height: 350, overflow: 'auto' }}>
                {specs.map((spec, index) => (
                    <div className="spec-card" key={index}>
                        <div className="spec-icon">
                            {/* Placeholder for icon */}
                            <img src={spec.img} alt="icon" />
                        </div>
                        <div className="spec-content">
                            <h3 style={{ fontWeight: 500 }}>{spec.title}</h3>
                            <p>{spec.content}</p>
                        </div>
                    </div>
                ))}
            </div>
        );
    };


    const gallery = () => {
        const gallery = [
            "https://www.concorde.in/assets-new/images/neo/gal/1.jpg",
            "https://www.concorde.in/assets-new/images/neo/gal/2.jpg",
            "https://www.concorde.in/assets-new/images/neo/gal/4.jpg",
            "https://www.concorde.in/assets-new/images/neo/gal/5.jpg",
            "https://www.concorde.in/assets-new/images/neo/gal/6.jpg",
            "https://www.concorde.in/assets-new/images/neo/gal/7.jpg",
            "https://www.concorde.in/assets-new/images/neo/gal/8.jpg",
            "https://www.concorde.in/assets-new/images/neo/gal/9.jpg"
        ]
        return (
            <div className="spec-container" style={{ height: 350, overflow: 'auto' }}>
                {gallery.map((item, index) => (
                    <div className="spec-card" key={index} style={{ border: 'none', padding: 0 }}>
                        <img src={item} alt="icon" style={{ width: '100%', height: 200 }} />
                    </div>
                ))}
            </div>
        )
    }

    const renderContent = () => {
        switch (activeTab) {
            case 'overview':
                return (
                    <div className="m-tab-content">
                        <h2 style={{ fontWeight: 600, marginBottom: '2%' }}>Let our compass guide you home</h2>
                        <p>
                            Concorde NEO is where modern living meets bold vision, shaping the future of Northeast Bengaluru.
                            With thoughtfully designed SMART homes and the exclusive Sky Evolve Clubhouse, it offers a refined
                            lifestyle that balances comfort with innovation. Positioned at the heart of a dynamic, evolving
                            location, NEO is your gateway to a life of distinction.
                        </p>
                        <div className="highlights">
                            <div className="highlight-item">
                                <span role="img" aria-label="area">📏</span>
                                <p>4.02 Acres</p>
                            </div>
                            <div className="highlight-item">
                                <span role="img" aria-label="bhk">🏠</span>
                                <p>2 & 3 BHK Homes</p>
                            </div>
                            <div className="highlight-item">
                                <span role="img" aria-label="floors">🏢</span>
                                <p>2B+G+18 Floors</p>
                            </div>
                            <div className="highlight-item">
                                <span role="img" aria-label="units">🔢</span>
                                <p>424 Units</p>
                            </div>
                            <div className="highlight-item">
                                <span role="img" aria-label="clubhouse">🏘️</span>
                                <p>7,000 Sq. ft. Signature Clubhouse</p>
                            </div>
                            <div className="highlight-item">
                                <span role="img" aria-label="towers">🏗️</span>
                                <p>6 Towers</p>
                            </div>
                        </div>
                        {/* <button className="download-brochure">DOWNLOAD BROCHURE</button> */}
                    </div>
                );
            case 'highlights':
                return <div className="m-tab-content">{highlights()}</div>;
            // case 'location':
            //     return <div className="m-tab-content">Location Content</div>;
            case 'masterplan':
                return <div className="m-tab-content" style={{ height: 400, overflow: 'auto' }}><FloorPlan /></div>;
            case 'clubhouse':
                return <div className="m-tab-content">{clubhouse()}</div>;
            case 'specifications':
                return <div className="m-tab-content">{specifications()}</div>;
            case 'gallery':
                return <div className="m-tab-content">{gallery()}</div>;
            default:
                return null;
        }
    };

    return (
        <div className="property-overview-container">
            <div className="sidebar">
                <ul>
                    <li className={activeTab === 'overview' ? 'active' : ''} onClick={() => setActiveTab('overview')}>Property Overview</li>
                    <li className={activeTab === 'highlights' ? 'active' : ''} onClick={() => setActiveTab('highlights')}>Property Highlights</li>
                    {/* <li className={activeTab === 'location' ? 'active' : ''} onClick={() => setActiveTab('location')}>Location</li> */}
                    <li className={activeTab === 'masterplan' ? 'active' : ''} onClick={() => setActiveTab('masterplan')}>Masterplan and Floor Plans</li>
                    <li className={activeTab === 'clubhouse' ? 'active' : ''} onClick={() => setActiveTab('clubhouse')}>Evolve ~ 7,000 sq. ft. Signature Clubhouse with 40+ Amenities</li>
                    <li className={activeTab === 'specifications' ? 'active' : ''} onClick={() => setActiveTab('specifications')}>Specifications</li>
                    <li className={activeTab === 'gallery' ? 'active' : ''} onClick={() => setActiveTab('gallery')}>Gallery</li>
                </ul>
            </div>

            <div className="content">
                {renderContent()}
            </div>
        </div>
    );
};

export default MainTabComp;