import AwardsTabs from "../components/AwardsTabs";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import HeroSection from "../components/HeroSection";
import JourneyStats from "../components/JourneyStats";
import SmartPhilosophy from "../components/SmartPhilosophy";
import TextAnimation from "../components/TextAnimation";
import Enquire from "../components/Enquire";
import Header from "../components/Header";
import ShowCase from "../components/ShowCase";
import MilestoneProperty from "../components/MilestoneProperty";
import Testimonials from "../components/Testimonials";
import MainTabComp from "../components/MainTabComp";

export default function Home() {
    return (
        <>
            <Header />
            <HeroSection />
            <MainTabComp />
            {/* <ShowCase /> */}
            <JourneyStats />
            <TextAnimation />
            <SmartPhilosophy />
            {/* <MilestoneProperty /> */}
            {/* <Testimonials /> */}
            {/* <AwardsTabs /> */}
            {/* <Enquire /> */}
            <ContactForm />
            <Footer />
        </>
    )
}