import React, { useState } from 'react';

const AwardsTabs = () => {
    const [activeTab, setActiveTab] = useState('awards');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const newsCardLayout = () => {
        const news = [
            {
                title: 'Concorde looks to achieve Rs 380-400 crore revenue in FY25',
                image: 'https://www.concorde.in/assets-new/images/news11.jpg',
                link: 'https://realty.economictimes.indiatimes.com/news/industry/concorde-group-looks-to-achieve-rs-550-600-crore-revenue-by-2026/113989744?utm_source=whatsapp_web&utm_medium=social&utm_campaign=socialsharebuttons',
            },
            {
                title: 'Bengaluru residential real estate soars amid high demand and limited inventory',
                image: 'https://www.concorde.in/assets-new/images/newnews1.jpg',
                link: 'https://www.thehindubusinessline.com/news/real-estate/bengaluru-residential-real-estate-soars-amid-high-demand-and-limited-inventory/article68618844.ece',
            },
            {
                title: 'Festive Season Sparks Optimism In Real Estate With Attractive Offers, Buyer Demand Surge',
                image: 'https://www.concorde.in/assets-new/images/newnews2.webp',
                link: 'https://businessworld.in/article/festive-season-sparks-optimism-in-real-estate-with-attractive-offers-buyer-demand-surge-532394',
            },
        ];
        return (
            <div className="n-card-layout">
                {news?.map((article, index) => (
                    <div className="n-card" key={index}>
                        <img src={article.image} alt={article.title} className="n-card-image" />
                        <div className="n-card-content">
                            <h3 className="n-card-title">{article.title}</h3>
                            <a href={article.link} className="n-card-button">READ MORE</a>
                        </div>
                    </div>
                ))}
                <button className="view-all-button">VIEW ALL</button>
            </div>
        );
    };


    const blogsCardLayout = () => {
        const blogs = [
            {
                title: 'Why Yelahanka is the Perfect Location for Your New Home',
                image: 'https://www.concorde.in/assets-new/images/blogs/why-yelahanka-is-the-perfect-location.jpg',
                link: 'https://www.concorde.in/blogs/why-yelahanka-is-the-perfect-location-for-your-new-home',
            },
            {
                title: 'Maximizing Tax Advantages Through Property Acquisition: A Guide for Homeowners',
                image: 'https://www.concorde.in/assets/images/blog/maximizing-tax-advantages.png',
                link: 'https://www.concorde.in/blogs/maximizing-tax-advantages-through-property-acquisition:-a-guide-for-homeowners/',
            },
            {
                title: 'Why Gated Communities Are Ideal for Families with Children',
                image: 'https://www.concorde.in/assetsnew/images/GatedCommunitiesTN.jpg',
                link: 'https://www.concorde.in/blogs/why-gated-communities-are-ideal-for-families-with-children/',
            },
        ];
        return (
            <>
                <div className="n-card-layout">
                    {blogs?.map((article, index) => (
                        <div className="n-card" key={index}>
                            <img src={article.image} alt={article.title} className="n-card-image" />
                            <div className="n-card-content">
                                <h3 className="n-card-title">{article.title}</h3>
                                <a target='_blank' href={article.link} className="n-card-button">READ MORE</a>
                            </div>
                        </div>
                    ))}
                    <button className="view-all-button">VIEW ALL</button>
                </div>
            </>
        );
    };




    return (
        <>
            <div style={{ background: '#FAFAFA' }}>

                <center>
                    <h3 style={{
                        fontSize: 15,
                        textTransform: 'uppercase',
                        color: '#0D5BA9',
                        margin: '5% auto 2%',
                        paddingTop: '5%'
                    }}>Discover the latest & Stay Informed</h3>
                </center>

                <div className="awards-container">
                    <div className="a-tabs">
                        <button
                            className={`a-tab-button ${activeTab === 'news' ? 'active' : ''}`}
                            onClick={() => handleTabChange('news')}
                        >
                            NEWS
                        </button>
                        <button
                            className={`a-tab-button ${activeTab === 'awards' ? 'active' : ''}`}
                            onClick={() => handleTabChange('awards')}
                        >
                            AWARDS
                        </button>
                        <button
                            className={`a-tab-button ${activeTab === 'blogs' ? 'active' : ''}`}
                            onClick={() => handleTabChange('blogs')}
                        >
                            BLOGS
                        </button>
                    </div>

                    {activeTab === 'awards' && (
                        <div className="cards-container">
                            <div className="card">
                                <img style={{ width: '40%' }} src="https://www.concorde.in/assets-new/images/aw2.png" alt="Silicon India Award" />
                                <h3>Silicon India Award</h3>
                                <p>
                                    Silicon Real Estate Awards 2015 for 'Best Luxury Villa Award' for Concorde Cupertino in South Bangalore - held at Lalit Ashok Bangalore. The award was received by Mr. Nesara - Executive Director, Kranti V. Alladi-Sales & Marketing Head, Concorde.
                                </p>
                            </div>
                            <div className="card">
                                <img style={{ width: '40%' }} src="https://www.concorde.in/assets-new/images/aw6.jpg" alt="Economic Times Best Realty Brand" />
                                <h3>Economic Times Best Realty Brand 2020-21</h3>
                                <p>
                                    We, at Concorde, thank our loyal customers, stakeholders, and employees for being a huge part of our journey driven by innovation, commitment and excellence over the last 2 decades.
                                </p>
                            </div>
                            <div className="card">
                                <img style={{ width: '40%' }} src="https://www.concorde.in/assets-new/images/aw5.png" alt="Investment Property Award" />
                                <h3>Investment Property Award at ET Achievers Karnataka 2023</h3>
                                <p>
                                    We are incredibly proud to share the news that Concorde Equity has been honoured with an Investment Property Award at ET Achievers Karnataka 2023.
                                </p>
                            </div>
                        </div>
                    )}


                    {activeTab === 'news' && (
                        <>
                            {newsCardLayout()}
                        </>
                    )}


                    {activeTab === 'blogs' && (
                        <>
                            {blogsCardLayout()}
                        </>
                    )}

                </div>
            </div>
        </>
    );
};

export default AwardsTabs;