import { useMediaQuery } from "@mui/material";

export default function HeroSection() {

    const isDesktop = useMediaQuery('(min-width: 768px)');

    return (
        <>
            <div>
                <img
                    src={isDesktop ? "/images/concorde-hero-img.jpg" : "/images/concorde-hero-img-mobile.jpg"}
                    style={{ width: '100%' }}
                />
            </div>
        </>
    )
}