import React, { useRef } from 'react';
import { useMediaQuery } from '@mui/material';
import emailjs from 'emailjs-com';

const ContactForm = () => {

    const form = useRef();
    const isTab = useMediaQuery('(max-width: 768px)');

    const sendEmail = (e) => {
        e.preventDefault();

        const formData = {
            name: form.current.user_name.value,
            phoneNumber: form.current.user_phone.value,
            email: form.current.user_email.value,
            message: form.current.message.value,
        };

        emailjs
            .sendForm(
                'service_cca6m3q', // Replace with your EmailJS service ID
                'template_n0zed61', // Replace with your EmailJS template ID
                form.current,
                'tEW1CgsKTucgD648R' // Replace with your EmailJS user ID
            )
            .then(
                (result) => {
                    alert("Thanks for contacting us!")
                },
                (error) => {
                }
            );
    };

    return (
        <div className="container">
            <div className="empathy-section">
                <div className="empathy-div">
                    <h2 style={{ fontWeight: 600, marginBottom: '4%' }}>Empathy</h2>
                    <p>We adopt empathy as a cornerstone business philosophy.</p> <br />
                    <p>
                        Guided by the needs and desires of both our customers and the society at large, we aim to be recognized as a
                        customer-centric and socially-responsible organization.
                    </p>
                    <div className="colorful-circle"></div>
                </div>
            </div>
            <div className="form-section">
                <h3>Enquire Now</h3>
                <h2 style={{ fontSize: 40, lineHeight: 1, marginBottom: '3%' }}>
                    Discover Your Perfect <br /> Property
                </h2>
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" name="user_name" placeholder="Name" required />
                    <div className="phone-email">
                        <input type="tel" name="user_phone" placeholder="Phone Number" required />
                        <input type="email" name="user_email" placeholder="Email" required />
                    </div>
                    <textarea name="message" placeholder="Message" rows="4"></textarea>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default ContactForm;

// Note: Replace 'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', and 'YOUR_USER_ID' with your actual EmailJS credentials.
