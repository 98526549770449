import React, { useState } from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube, FaLinkedinIn, FaFacebook } from 'react-icons/fa';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';

const Footer = () => {

    const [tabValue, setTabValue] = useState(0);
    const isDesktop = useMediaQuery('(min-width: 768px)');

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const residentialProjects = [
        { title: 'Concorde Mayfair', link: 'https://www.concorde.in/mayfair/luxury-apartments-in-yelahanka/' },
        { title: 'Concorde Antares', link: 'https://www.concorde.in/antares/apartments-in-vidyaranyapura-north-bangalore/' },
        { title: 'Concorde Equity', link: 'https://www.concorde.in/concorde-equity/' },
        { title: 'Concorde Abode 99', link: 'https://www.concorde.in/abode99/luxury-villas-in-sarjapur-road/' },
        { title: 'Concorde Elements of Earth', link: 'https://www.concorde.in/elements-of-earth/' },
        { title: 'Concorde Auriga', link: 'https://www.concorde.in/apartments-in-kr-puram/' },
        { title: 'Concorde Luxepolis', link: 'https://www.concorde.in/apartments-in-basavanagudi/luxepolis/' },
        { title: 'Concorde Spring Meadows', link: 'https://www.concorde.in/apartments-in-tumkur-road/spring-meadows/' },
        { title: 'Concorde Napa Valley', link: 'https://www.concorde.in/villas-in-bangalore/napa-valley/' }
    ]

    const residentialProjects2 = [
        { title: 'Concorde Hillcrest', link: 'https://www.concorde.in/plots-in-devanahalli/hillcrest/' },
        { title: 'Concorde Opus One', link: 'https://www.concorde.in/residential-plots-for-sale-in-bangalore/opus-one/' },
        { title: 'Concorde Opus Two', link: 'https://www.concorde.in/opus-two/villa-plots-in-kanakapura-road/' },
        { title: 'Concorde Epitome', link: 'https://www.concorde.in/flats-in-electronic-city/epitome/' },
        { title: 'Concorde Manhattans', link: 'https://www.concorde.in/apartments-in-e-city/manhattans/' },
        { title: 'Concorde Cupertino', link: 'https://www.concorde.in/villas-in-electronic-city-bangalore/cuppertino/' },
        { title: 'Concorde Tech Turf', link: 'https://www.concorde.in/apartments-in-electronic-city/techturf/' },
        { title: 'Concorde Mist Valley', link: 'https://www.concorde.in/plots-in-bangalore/mist-valley/' },
    ]

    const residentialProjects3 = [
        { title: 'Concorde Econex', link: 'https://www.concorde.in/econex/' },
        { title: 'Concorde Wind Rush', link: 'https://www.concorde.in/electronic-city/windrush-apartments/' },
        { title: 'Concorde Amber', link: 'https://www.concorde.in/apartments-sarjapur-road/amber/' },
        { title: 'Concorde Midway City', link: 'https://www.concorde.in/electronic-city/apartments/midway-city/' },
        { title: 'Concorde Sylvan View', link: 'https://www.concorde.in/electronic-city/villas/sylvan/' },
        { title: 'Concorde Silicon Valley', link: 'https://www.concorde.in/electronic-city/villas/silicon-valley/' },
        { title: 'Concorde South Scape', link: 'https://www.concorde.in/hosur-road/apartments/south-scape/' },
        { title: 'Concorde Livingston', link: 'https://www.concorde.in/apartments-in-hosur-road/livingston/' },
    ]

    const commercialProjects = [
        { title: 'Concorde Econex', link: 'https://www.concorde.in/econex/' },
        { title: 'Concorde Maruthi Tech Park', link: 'https://www.concorde.in/commercial.php' },
        { title: 'Concorde Anthuriums', link: 'https://www.concorde.in/commercial.php' },
        { title: 'Concorde Arcade', link: 'https://www.concorde.in/commercial.php' },
        { title: 'Concorde Metropolis', link: 'https://www.concorde.in/commercial.php' },
        { title: 'Concorde Towers', link: 'https://www.concorde.in/commercial.php' },
        { title: 'Concorde Plaza', link: 'https://www.concorde.in/commercial.php' },
        { title: 'Concorde Nexus', link: 'https://www.concorde.in/commercial.php' },
    ]


    return (
        <footer className="footer">
            <div className="footer-div">

                <div className="footer-bottom"
                    style={{
                        marginTop: 0,
                        borderBottom: '1px solid #252E3D',
                        paddingBottom: '1%',
                        display: 'flex'
                    }}
                >
                    <div>
                        <img
                            src='/images/footer-logo.png'
                        />
                    </div>
                    {/* <div className="footer-links-bottom" style={{ marginLeft: 'auto' }}>
                        <ul>
                            <li><a href="#">SOP Guidelines</a></li>
                            <li><a href="#">Home Loans</a></li>
                            <li><a href="#">Metric Converter</a></li>
                            <li><a href="#">Booking Form</a></li>
                            <li><a href="#">EOI</a></li>
                            <li><a href="#">NRI</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                        </ul>
                    </div> */}
                </div>

                <div>
                    <p style={{ fontSize: 13, opacity: '80%', marginTop: '3%' }}>
                        Address - Sahakarnagar, Bangalore. <br /><br /><br />
                        {/* Contact - +91 8494811119, +91 9535177911. <br /><br /><br /> */}
                        This is not an offer, an invitation to offer, and/or commitment of any nature. This contains artistic impressions and stock images for illustrative purpose and no warranty is expressly or impliedly given that the completed development will carply in any degree with such artist's impression as depicted. All specifications shall be as per the final agreement between the parties, Recipients are advised to use their discretion in relying on the information/ amenities described/shown therein, Premium specifications are subject to agreed/additional cost. T&C apply. E & OE. <br /> <br />
                        - 2024 channel partner of Concorde. We are committed to protecting and processing your personal data responsibly. Copyright  @ channel partner of  Mayfair 2024. All rights reserved.
                    </p>
                </div>

                {/* <div className="footer-top">
                    <div className="footer-tabs-social">
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'left' }}>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                centered
                                TabIndicatorProps={{ style: { backgroundColor: '#ffffff' } }}
                                textColor="inherit"
                            >
                                <Tab label="Residential Properties" sx={{ color: tabValue === 0 ? '#ffffff' : '#b1b1b1' }} />
                                <Tab label="Commercial Projects" sx={{ color: tabValue === 1 ? '#ffffff' : '#b1b1b1' }} />
                            </Tabs>
                        </Box>
                        <div className="footer-social">
                            <h4 style={{ fontSize: 15, textAlign: 'left' }}>Follow Us</h4>
                            <div className="social-icons">
                                <a target='_blank' href="https://www.facebook.com/ConcordeIN" aria-label="Facebook"><FaFacebook fontSize={17} /></a>
                                <a target='_blank' href="https://twitter.com/ConcordeIndia" aria-label="Twitter"><FaTwitter fontSize={17} /></a>
                                <a target='_blank' href="https://www.instagram.com/concorde.in/" aria-label="Instagram"><FaInstagram fontSize={17} /></a>
                                <a target='_blank' href="https://www.youtube.com/@concordeIn" aria-label="YouTube"><FaYoutube fontSize={17} /></a>
                                <a target='_blank' href="https://www.linkedin.com/company/concorde-group/" aria-label="LinkedIn"><FaLinkedinIn fontSize={17} /></a>
                            </div>
                        </div>
                    </div>
                    <div className="footer-links">
                        {tabValue === 0 && (
                            <>
                                <div className="footer-section">
                                    <ul style={{ width: '100%' }}>
                                        {residentialProjects?.map((item, index) => (
                                            <li key={index}>{item.title}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="footer-section">
                                    <ul style={{ marginLeft: isDesktop ? 0 : 0, marginTop: isDesktop ? '' : '10%', width: '100%' }}>
                                        {residentialProjects2?.map((item, index) => (
                                            <li key={index}>{item.title}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="footer-section">
                                    <ul style={{ marginLeft: isDesktop ? 0 : 0, marginTop: isDesktop ? '' : '10%', width: '100%' }}>
                                        {residentialProjects3?.map((item, index) => (
                                            <li key={index}>{item.title}</li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        )}
                        {tabValue === 1 && (
                            <div className="footer-section">
                                <ul>
                                    {commercialProjects.map((item, index) => (
                                        <li key={index}>{item.title}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div> */}

                {/* <div className="footer-bottom">
                    <div className="footer-links-bottom">
                        <ul>
                            <li><a href="#">SOP Guidelines</a></li>
                            <li><a href="#">Home Loans</a></li>
                            <li><a href="#">Metric Converter</a></li>
                            <li><a href="#">Booking Form</a></li>
                            <li><a href="#">EOI</a></li>
                            <li><a href="#">NRI</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                        </ul>
                    </div>
                    <p>Copyright &copy; 2024 - Concorde, All rights reserved</p>
                </div> */}
            </div>
        </footer>
    );
};

export default Footer;
