import React from 'react';

const JourneyStats = () => {
    return (
        <div className="journey-container">
            <h2 className="journey-title">Journey Thus Far</h2>
            <div className="journey-stats">
                <div className="stat-item">
                    <h3>25+ million sq. ft.</h3>
                    <p>Completed Properties</p>
                </div>
                <div className="stat-item">
                    <h3>6+ million sq. ft.</h3>
                    <p>Ongoing Properties</p>
                </div>
                <div className="stat-item">
                    <h3>14,000+</h3>
                    <p>Happy Customers</p>
                </div>
            </div>
        </div>
    );
};

export default JourneyStats;
